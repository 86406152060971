import React from 'react';
import styles from './Main.scss';
import ModalManager from '../ModalManager';
import Routes from '../Routes';
import classNames from 'classnames';
import stylesParams from '../../stylesParams';
import { useStyles } from '@wix/tpa-settings/react';
import { useEnvironment, useExperiments } from 'yoshi-flow-editor-runtime';

export interface MainProps {
  isRTL: boolean;
  isMobile: boolean;
  compId: string;
}

function useEnvironmentSafe() {
  try {
    return useEnvironment();
  } catch (e) {
    return { isPreview: false, isEditor: false };
  }
}

const Main: React.FC<MainProps> = React.memo(({ isRTL, isMobile, compId }) => {
  const tpaStyles = useStyles();
  const isEditorX = tpaStyles.get(stylesParams.responsive);
  const { isEditor, isPreview } = useEnvironmentSafe();
  const isClassicEditor = isEditor && !isEditorX;
  const experiments = useExperiments();
  const shouldUseClassicEditorBreakpoint = experiments.experiments.enabled('specs.restaurants.mobileEditorCSSBug');

  React.useEffect(() => {
    const selector = `.${compId}`;
    const elem = document.querySelector(selector);
    if (elem) {
      elem.setAttribute('dir', isRTL ? 'rtl' : 'ltr');
    }
  }, [isRTL, compId]);

  return (
    <div
      id="modal-wrapper"
      className={classNames({
        'restaurants-mobile-classic-editor-breakpoint':
          shouldUseClassicEditorBreakpoint && isMobile && (isClassicEditor || (isPreview && !isEditorX)),
      })}
    >
      <div id="main-page" className={styles.wrapper} data-hook="MainPage-wrapper" dir={isRTL ? 'rtl' : 'ltr'}>
        <Routes />
      </div>
      <ModalManager />
    </div>
  );
});

Main.displayName = 'Main';

export default Main;
